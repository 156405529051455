import {useLocation} from "@remix-run/react"
import {Outlet} from "@remix-run/react"
import clsx from "clsx"
import React from "react"
import * as ModalActionCreators from "~/actions/ModalActionCreators"
import * as UserSettingsModalActionCreators from "~/actions/UserSettingsModalActionCreators"
import {AutoUpdateManager} from "~/components/layout/AutoUpdateManager"
import {SplashScreen} from "~/components/layout/SplashScreen"
import {MfaTotpEnableModal} from "~/components/modals/MfaTotpEnableModal"
import Dispatcher from "~/flux/Dispatcher"
import {i18n} from "~/i18n"
import {ClearIcon} from "~/icons/ClearIcon"
import AuthenticationStore from "~/stores/AuthenticationStore"
import NagbarStore from "~/stores/NagbarStore"
import UserStore from "~/stores/UserStore"
import * as RouterUtils from "~/utils/RouterUtils"

const NON_AUTHED_PATHNAMES = new Set(["/login", "/register"])

export const AppLayout = () => {
  const {hideMfaNagbar} = NagbarStore.useStore()
  const {token} = AuthenticationStore.useStore()
  const location = useLocation()
  const user = UserStore.useCurrentUser()
  const shouldShowMfaNagbar = user && !(user.mfaEnabled || hideMfaNagbar)

  React.useEffect(() => {
    if (token) {
      Dispatcher.dispatch({type: "SESSION_START", token: AuthenticationStore.getToken()})
    } else if (!NON_AUTHED_PATHNAMES.has(location.pathname)) {
      RouterUtils.replaceWith("/login")
    }
  }, [token, location.pathname])

  return (
    <>
      {token && <SplashScreen />}
      <AutoUpdateManager />
      <div
        className={clsx(
          "grid h-[100svh] overflow-hidden bg-background-primary text-text-primary",
          shouldShowMfaNagbar ? "grid-cols-1 grid-rows-[auto,1fr]" : "grid-cols-1 grid-rows-1",
        )}
      >
        {shouldShowMfaNagbar && <Nagbar />}
        <Outlet />
      </div>
    </>
  )
}

const Nagbar = () => {
  const user = UserStore.useCurrentUser()
  if (!user) {
    return null
  }

  const enableTwoFactor = () => {
    UserSettingsModalActionCreators.openUserSettingsTab("my_account")
    ModalActionCreators.push(() => <MfaTotpEnableModal />)
  }

  return (
    <div className="relative flex h-[36px] items-center justify-center bg-brand-primary px-2 py-1 font-semibold text-brand-primary-fill text-sm">
      <p>
        {i18n.format(i18n.Messages.MFA_NOT_ENABLED_NAGBAR, {
          username: user.displayName,
          enableTwoFactor: (
            <span
              key="enableTwoFactor"
              role="button"
              tabIndex={0}
              className="cursor-pointer underline"
              onClick={enableTwoFactor}
              onKeyDown={(e) => e.key === "Enter" && enableTwoFactor()}
            >
              {i18n.Messages.ENABLE_TWO_FACTOR}
            </span>
          ),
        })}
      </p>
      <button
        type="button"
        className="absolute top-2 right-2 border-none bg-transparent"
        aria-label={i18n.Messages.CLOSE}
        onClick={() => Dispatcher.dispatch({type: "HIDE_MFA_NAGBAR"})}
      >
        <ClearIcon className="h-5 w-5" />
      </button>
    </div>
  )
}
