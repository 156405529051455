import {i18n} from "~/i18n"

const getSplashScreenTitles = () => [
  i18n.Messages.SPLASH_SCREEN_TITLE_1,
  i18n.Messages.SPLASH_SCREEN_TITLE_2,
  i18n.Messages.SPLASH_SCREEN_TITLE_3,
  i18n.Messages.SPLASH_SCREEN_TITLE_4,
  i18n.Messages.SPLASH_SCREEN_TITLE_5,
  i18n.Messages.SPLASH_SCREEN_TITLE_6,
  i18n.Messages.SPLASH_SCREEN_TITLE_7,
  i18n.Messages.SPLASH_SCREEN_TITLE_8,
  i18n.Messages.SPLASH_SCREEN_TITLE_9,
  i18n.Messages.SPLASH_SCREEN_TITLE_10,
  i18n.Messages.SPLASH_SCREEN_TITLE_11,
  i18n.Messages.SPLASH_SCREEN_TITLE_12,
  i18n.Messages.SPLASH_SCREEN_TITLE_13,
  i18n.Messages.SPLASH_SCREEN_TITLE_14,
  i18n.Messages.SPLASH_SCREEN_TITLE_15,
  i18n.Messages.SPLASH_SCREEN_TITLE_16,
  i18n.Messages.SPLASH_SCREEN_TITLE_17,
  i18n.Messages.SPLASH_SCREEN_TITLE_18,
  i18n.Messages.SPLASH_SCREEN_TITLE_19,
  i18n.Messages.SPLASH_SCREEN_TITLE_20,
]

export const SplashScreenUtils = {
  getSplashScreenTitle(initialLoadTime: number): string {
    const titleList = getSplashScreenTitles()
    return titleList[initialLoadTime % titleList.length]
  },
}
